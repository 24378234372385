@import url('https://fonts.googleapis.com/css2?family=Orelega+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orelega+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  background-color: #fff6ee;
}

blockquote {
  font-family: 'Roboto', sans-serif;
  border-left: 5px solid #1d1d1d;
  padding-left: 10px;
  font-size: 1.6rem;
  line-height: 1.31em;
  margin: 20px auto;
}

blockquote:before {
  font-family: 'Roboto', sans-serif;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.2em;
  vertical-align: -0.4em;
  color: #fcbe11;
}

a {
  color: inherit;
  text-decoration: underline;
  text-decoration-color: #c73f1a;
  -webkit-text-decoration-color: #c73f1a;
}

h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 3.5rem;
}

p {
  font-size: 16px;
  line-height: 1.5;
}

.light-black {
  color: #454545;
}

.vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.callout-text {
  font-family: 'Orelega One';
  font-size: 3rem;
  text-align: left;
  padding: 20px;
  line-height: 2.8rem;
  width: 80%;
}

.callout-right-side {
  transform: translateX(20%);
}

.call-to-action-row {
  border-top: 5px solid #181818;
  border-bottom: 5px solid #181818;
  margin: 30px auto;
}

.call-to-action {
  margin-left: 25px;
}

.call-to-action-section-wrapper h1 {
  font-size: 3rem;
  line-height: 5rem;
  text-align: center;
}

.call-to-action h1 {
  margin-top: 0px;
}

.orange-text {
  color: #c73f1a;
}

.yellow-text {
  color: #fcbe11;
}

.blue-text {
  color: #4382eb;
}

.pink-text {
  color: #f7a8b8;
}

.green-text {
  color: #008683;
}

.callout-blue {
  background-color: #4382eb;
  color: #fff6ee;
}
.callout-pink {
  background-color: #f7a8b8;
  color: #fff6ee;
}
.callout-green {
  background-color: #008683;
  color: #fff6ee;
}
.callout-orange {
  background-color: #c73f1a;
  color: #fff6ee;
}
.callout-yellow {
  background-color: #fcbe11;
  color: #fff6ee;
}

.callout-text-attribution {
  font-size: 1rem;
}

section {
  max-width: 740px;
  margin: 0 auto;
  display: flex;
}

.column {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  border-radius: 5px;
  margin: 5px;
  width: 100%;
  border: 1px solid lightgray;
}

.active-stage {
  background-color: #fefb72;
}

ul {
  list-style-type: none;
  text-align: left;
  margin: 10px auto;
  max-width: 600px;
  padding: 20px;
}

/* All Bills */

.all-bills-container {
  max-width: 70vw;
  margin: 0 auto;
}

.all-bills-container-open {
  max-height: 100%;
}

.all-bills-container-closed {
  max-height: 580px;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
}

.all-bills-column {
  float: left;
}

.all-bills-left {
  width: 30%;
  height: 20px;
}

.all-bills-right {
  width: 70%;
  margin-top: 10px;
  height: 20px;
}

.all-bills-row:after {
  content: '';
  display: table;
  clear: both;
  max-width: 600px;
}

.all-bills-expand-button,
.clear-bills-button {
  /* border: 1px solid #ddd;
  padding: 10px;
  background-color: #fff;
  color: #181818;
  font-size: 16px;
  margin-top: 15px; */
  padding: 6px 14px 6px 12px;
  border: 1px solid currentColor;
  color: #454545;
  background: none;
  cursor: pointer;
  font-weight: bold;
  background-color: #fff;
  padding: 10px;
  margin-top: 10px;
  border-radius: 4px;
}

/* Bill Card Styles */

.card {
  display: flex-start;
  flex-wrap: wrap;
  align-items: center;
  -moz-box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 30px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 30px;
}

.card-content {
  padding: 16px 32px;
  flex: 4 1 40ch;
  text-align: left;
  background-color: #fff;
  height: 420px;
}

.card-content-org {
  padding: 16px 32px;
  flex: 4 1 40ch;
  text-align: left;
  background-color: #fff;
}

.card-desc {
  text-align: left;
}

.card-tags {
  margin: 0 -8px;
}

.card-tag {
  display: inline-block;
  margin: 8px;
  font-size: 0.875em;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: #e05d26;
}

.card-title {
  margin: 0;
  font-size: clamp(1.4em, 2.1vw, 2.1em);
}

.card-title a {
  text-decoration: none;
  color: inherit;
}

.card-metadata {
  margin: 0;
}

.card-rating span {
  color: #454545;
}

.card-view-bill {
  display: flex;
  align-items: center;
  padding: 6px 14px 6px 12px;
  border-radius: 4px;
  border: 2px solid currentColor;
  color: #454545;
  background: none;
  cursor: pointer;
  font-weight: bold;
  background-color: #fff;
}

.card-view-bill svg {
  margin-right: 6px;
}

.card-container {
  color: #454545;
  line-height: 1.4;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  /* max-width: 1600px; */
  margin: 0 auto;
  max-width: 1100px;
}

.card-container-open {
  max-height: 100%;
}

.card-container-closed {
  max-height: 700px;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 70%, transparent 100%);
}

.small {
  width: clamp(320px, 35%, 400px);
  padding: 20px;
}

.medium {
  width: clamp(300px, 22%, 1200px);
  padding: 10px;
}

.slim-view {
  width: 70vw;
  margin: 20px auto;
  text-align: left;
}

.section-wrapper {
  width: 70vw;
  max-width: 800px;
  margin: 6vh auto;
}

.section-wrapper {
  width: 70vw;
  max-width: 800px;
  margin: 5vh auto;
}

.max-width-600 {
  max-width: 600px;
  margin-left: 20px;
}

/* US Map */

path {
  pointer-events: all;
}

path:hover {
  opacity: 0.7;
  cursor: pointer;
}

/* general flexbox row & column */
.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.flex-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  transition: width 2s, height 2s, background-color 2s, transform 2s;
}

.sticky-column {
  position: sticky;
  top: 25px;
}

.process-bar-chat-description {
  text-align: center;
  width: 100%;
  left: 0;
  margin-bottom: 10px;
}

.progress-bar-chart-label {
  text-align: right;
  width: 100%;
  right: 0;
  margin-bottom: 10px;
  color: #c73f1a;
}

.frontier-blockquote {
  margin: auto;
  margin-left: 20px;
  max-width: 800px;
}

.us-state-map {
  max-width: 100%;
}

.map-in-column {
  margin: 0 auto;
  transform: translateX(3vw);
}

.map-flex-row {
  min-height: 900px;
}

.data-citation {
  width: 100%;
  text-align: center;
  margin-bottom: 25px;
}

.align-left {
  align-items: left;
}

/* On screens with small widths */
@media screen and (max-width: 1000px) {
  .flex-column {
    flex: 50%;
  }
  .flex-row {
    flex-direction: column;
    /* max-width: 70vw; */
    margin: 0 auto;
  }
  .sticky-column {
    position: sticky;
    top: 0;
    width: 100vw;
  }
  .callout-text {
    width: 100%;
  }
  .callout-right-side {
    transform: translateX(0%);
  }
  .all-bills-left,
  .all-bills-right {
    width: 100%;
    height: 20px;
  }
  .all-bills-container {
    max-width: 100vw;
  }
}

@media (max-width: 500px) {
  .us-state-map {
    max-width: 100%;
    height: 50vw;
  }
  .map-section {
    width: 100vw;
    margin-left: 12vw;
  }
  .flex-row {
    max-width: 100vw;
  }
  .flex-column {
    max-width: 100vw;
  }
}

.fade-in-500 {
  animation: fadeIn 500ms;
}
.fade-in-1000 {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
